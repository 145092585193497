module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aymkay","short_name":"Aymkay","start_url":"/","background_color":"#333333","theme_color":"#001c43","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
